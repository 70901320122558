.alert {
    position: fixed;
  top: 80px;
  width: 100%;
  height: fit-content;
  padding: 20px;
  background-color: #044d57;
  display: flex;
  justify-content: space-between;
  cursor: pointer;;
}

.alert a:hover {
  text-decoration: underline;
}


.dimiss {
  width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.502);
    border-radius: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  cursor: pointer;
}

.d-none {
  display: none;
}